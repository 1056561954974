import React from "react";
import { Link } from "react-router-dom";
import offer from "../../../assets/img/more-service2.jpg";

const SpecialOffer = () => {
  const items = [
    "Exclusively Frames",
    "Wooden Doors",
    "Apartments",
    "Renovation",
    "Any thing related to wooden",
    "Natural materials",
    "Modern design",
  ];

  return (
    <>
      <div className="SpecialOffer sectionSpace bgGray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="offerImg">
                <Link to={"/service-details"}>
                  <img src={offer} alt="Special Offer" className="img-fluid" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="offerContent">
                <h3>Our Special Offers</h3>
                <p>
                  Using only the best wood and materials, we take great care to
                  craft every piece we make with exacting attention to detail.
                  From small ornate pieces like jewellery boxes, to massive
                  pieces of furniture, each item is crafted to meet our
                  customer's needs. We pride ourselves in making sure our
                  clients receive the highest quality items available.
                </p>
                <ul>
                  {items.map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialOffer;
