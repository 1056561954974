import React from "react";

import home2 from "../../../assets/img/ab.jpg";

import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList sectionPD bgGray">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="aboutImg">
                <img src={home2} alt="About" className="img-fluid" />
                <span>Handmade wooden wonders for you</span>
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutWooden">
                <span>colors</span>
                <h2>shades of elegance </h2>
                <p>
                  We are carpenters, our skill known to all across the land.
                  With an impeccable reputation, we have built a stellar name
                  for ourselves and a portfolio of work that can be found far
                  and wide.
                </p>
                <p>
                  Using only the best wood and materials, we take great care to
                  craft every piece we make with exacting attention to detail.
                  From small ornate pieces like jewellery boxes, to massive
                  pieces of furniture, each item is crafted to meet our
                  customer's needs. We pride ourselves in making sure our
                  clients receive the highest quality items available.
                </p>
                <ul>
                  <li>Wooden Doors</li>
                  <li>Apartments</li>
                  <li>Renovation</li>
                  <li>Exclusively Frames</li>
                </ul>
                <Link to={"/"}>read more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
