import React from "react";
import offer from "../../assets/img/more-service2.jpg";
import { Link } from "react-router-dom";
const listData = [
  {
    id: 1,
    title: "Project Management",
    content:
      "Project management is the planning, organizing and managing of tasks and resources to accomplish a defined objective, usually with constraints on time and cost. Most projects, whether they are large and complex or small and simple, can be planned by breaking the project into small, easily manageable tasks, scheduling the tasks, and then tracking the tasks as work progresses.",
  },
  {
    id: 2,
    title: "Reporting and Cost Control",
    content:      "Reporting and cost control is a crucial practice in business management that involves actively monitoring and analyzing expenses across various departments, comparing them to budgeted amounts, and generating detailed reports to identify areas of overspending or inefficiencies, allowing businesses to take corrective actions, optimize resource allocation, and ultimately maximize profitability by minimizing unnecessary costs.",
  },
  {
    id: 3,
    title: "Coordination Process",
    content:
      "Coordination is a management process that involves bringing together individuals or parties to achieve a common goal. It's an important aspect of any group effort, and is considered the essence of management",
  },
  {
    id: 4,
    title: "Personal Sentiments",
    content:
      "A personal sentiment is a personal belief or judgment that is not based on proof or certainty. Here are some tips for writing a paragraph about your personal sentiments",
  },
  {
    id: 5,
    title: "Commissioning and Project Closeout",
    content:
      "Commissioning project closeout is a defined process for documenting commissioning system performance, so that the documentation can be verified and approved. A typical process would have the commissioning agent submit the turnover commissioning record documentation for review. The Project Manager then reviews and approves the submission of the closeout commissioning record.",
  },
  {
    id: 6,
    title: "Personal Sentiments (Repeat)",
    content:
      "A personal sentiment is a personal belief or judgment that is not based on proof or certainty. Here are some tips for writing a paragraph about your personal sentiments",
  },
];

const serve = [
  {
    id: 1,
    title: "Emergency Services",
    content:
      "Emergency services are organizations that are responsible for public safety, health, and security by addressing and resolving emergencies",
  },
  {
    id: 2,
    title: "Guaranteed Works",
    content:
      "Express your passion: Explain why you're passionate about the company's product, mission, or service.  ",
  },
  {
    id: 3,
    title: "Fast Services",
    content:
      "A paragraph is a series of sentences that are related to a single topic and are organized and coherent.",
  },
  {
    id: 4,
    title: "Affordable Price",
    content:
      "One of the key issues here is offering that equipment at an affordable price",
  },
  {
    id: 5,
    title: "Highly Trained Staff",
    content:
      "A highly trained staff refers to a team of employees who have undergone extensive and specialized training...",
  },
  {
    id: 6,
    title: "Free Consultation",
    content:
      "A free consultation is a one-on-one meeting, conversation, or interview where a person can share their thoughts and discuss...",
  },
];

const ServiceDetail = () => {
  return (
    <>
      <div className="serviceDetail sectionSpace">
        <div className="container">
          <div className="weOffer">
            <div className="row">
              <div className="col-lg-6">
                <div className="offerImg">
                  <Link to={"/service-details"}>
                    <img
                      src={offer}
                      alt="Special Offer"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="offerContent">
                  <h3>What We Offer</h3>
                  <p>
                  We are carpenters, our skill known to all across the land.
                  With an impeccable reputation, we have built a stellar name
                  for ourselves and a portfolio of work that can be found far
                  and wide.
                  </p>
                  <p>
                  Using only the best wood and materials, we take
                  great care to craft every piece we make with exacting
                  attention to detail. From small ornate pieces like jewellery
                  boxes, to massive pieces of furniture, each item is crafted to
                  meet our customer's needs. We pride ourselves in making sure
                  our clients receive the highest quality items available.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="listData sectionSpace bgGray">
        <div className="container">
          <div className="row">
            {listData.map((item) => {
              return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                  <div className="listCard">
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="listData sectionSpace">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="fisherman-content">
                <h3>Specific Offers of The Service </h3>
              </div>
            </div>
            {serve.map((item) => {
              return (
                <div className="col-lg-4 col-md-4" key={item.id}>
                  <div className="listCard">
                    <div className="icon">{item.id}</div>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetail;
