import React from "react";

function RetunsRefundCancellation() {
  return (
    <section className="termsSec">
      <div className="container">
        <div className="termsInfo">
          <h4>Returns & Refund & Cancellation</h4>
          <div class="panel-body">
            <p>
              1. <strong>Cancellations:</strong>
            </p>
            <p>
              We have a cancellation rule for orders which haven’t left the
              store for delivery.
            </p>
            <p>
              All orders that leave the store to be delivered, but gets
              cancelled – we will charge QAR 10 /- as cancelation fee (driver
              expenses).
            </p>

            <p>
              2. <strong>Returns:</strong>
            </p>
            <p>
              We have a 7-day exchange rule. The receipt/proof of purchase is to
              be submitted with the purchased product is to be unused and in the
              original packaging. We cannot cash refund anything sold both at
              the store and online. You would receive an electronic credit
              voucher to use within 6 months.
            </p>

            <p>
              3. <strong>Credit Note:</strong>
            </p>
            <p>
              Credit note is issued against any returned item. To claim the
              credit note, it should be done within 6 months.
            </p>
            <p>
              Also, proof of credit note should be shown once claim is
              requested.
            </p>

            <p>
              4. <strong>Eligibility:</strong>
            </p>
            <p>*Factory defaulted products</p>
            <p>*Purchased a wrong size</p>

            <p>
              5. <strong>Nonexchangeable items:</strong>
            </p>
            <p>
              *Health and Personal Care items: Bras, panties and nursing items.
            </p>
            <p>*Products purchased during Sales and Promotions</p>

            <p>
              6. <strong>How does this work?</strong>
            </p>
            <p>
              An e-mail is to be sent to info@aldarwishcarpentry.com A brief
              description of what is the case with a picture of both the item
              and the receipt is to be attached. A response would be given
              within 5 working days.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RetunsRefundCancellation;
